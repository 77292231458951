import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';
import { globalMixin } from '@/mixins';

import PageNotFound from '../views/public/PageNotFound.vue';
import Login from '../views/public/Login.vue';
import Registration from '../views/public/registration/Registration.vue';
import VerifyEmail from '../views/public/VerifyEmail.vue';
import Lectures from '../views/user/lectures/Lectures.vue';
import SingleLecture from '../views/user/lectures/SingleLecture.vue';
import CorticoConsultRequest from '../views/public/ExternalReferralRequest/CorticoRequest.vue';
import UserInviteAcceptLinkHandler from '../views/public/UserInviteAcceptLinkHandler.vue';
// import DemoLogin from '../views/public/DemoLogin.vue';
// import Logout from '../views/user/Logout.vue';
// import AccountSetupPage from '../views/user/onboarding/AccountSetupPage.vue';
// import RegistrationThankYou from '../views/public/registration/RegistrationThankYou.vue';
// import GeneralPrivacyPolicy from '../views/public/privacy/GeneralPrivacyPolicy.vue';
// import PlatformPrivacyPolicy from '../views/public/privacy/PlatformPrivacyPolicy.vue';
// import TermsOfService from '../views/public/privacy/TermsOfService.vue';
// import Home from '../views/user/Home.vue';
// import ReferralHistory from '../views/user/referral/History.vue';
// import AllNotifications from '../views/user/my_account/AllNotifications.vue';
// import ReferralTriage from '../views/user/referral/ReferralTriage.vue';
// import ShowReferral from '../views/user/referral/Show.vue';
// import MakeReferral from '../views/user/gp/MakeReferral.vue';
// import Schedules from '../views/user/specialist/schedule/All.vue';
// import Preferences from '../views/user/specialist/Preferences.vue';
// import ExampleConsultForm from '../views/user/specialist/ExampleConsultForm.vue';
// import Onboarding from '../views/user/onboarding/Onboarding.vue';
// import AddSchedule from '../views/user/specialist/schedule/AddSchedule/AddSchedule.vue';
// import PasswordReset from '../views/public/PasswordReset.vue';
// import PasswordResetRequest from '../views/public/PasswordResetRequest.vue';
// import EmailNotVerified from '../views/public/EmailNotVerified.vue';
// import ShowMyAccount from '../views/user/my_account/ShowMyAccount.vue';
// import CloseAccount from '../views/user/my_account/CloseAccount.vue';
// import InviteColleague from '../views/user/InviteColleague.vue';
// import LegacyReRegister from '../views/user/legacy_reregister/LegacyReRegister.vue';
// import LectureCredits from '../views/user/lectures/LectureCredits.vue';
// import ManageUsers from '../views/user/org_unit/ManageUsers.vue';
// import AddUser from '../views/user/org_unit/AddUser.vue';
// import EditUser from '../views/user/org_unit/EditUser.vue';
// import InviteUser from '../views/user/org_unit/InviteUser.vue';
// import AddExistingUserToOrgUnit from '../views/user/org_unit/AddExistingUser.vue';
// import ShareReferralsWithOrgUnit from '../views/user/referral/ShareReferralsWithOrgUnit.vue';
// import SmartPathBuilder from '../views/user/specialist/smartpath/Builder.vue';
// import SmartPaths from '../views/user/specialist/smartpath/SmartPaths.vue';
// import SmartPath from '../views/user/gp/make_smartpath/SmartPath.vue';
// import SmartPathTransactions from '../views/user/specialist/smartpath/SmartPathTransactions.vue';
// import SmartPathTransactionHistory from '../views/user/gp/smartpath/SmartPathTransactionHistory.vue';
// import ViewTransaction from '../views/user/specialist/smartpath/ReviewTransaction.vue';
// import AllResponseFormRelatedFiles from '../views/user/AllResponseFormRelatedFiles.vue';
// import Billing from '../views/user/billing/Billing.vue';
// import NotifyUserFromTextMessage from '../views/user/NotifyUserFromTextMessage.vue';
// import VerifyEmailUpdate from '../views/public/VerifyEmailUpdate.vue';
// import RecentCallsHistory from '../views/user/caller/RecentCallsHistory.vue';
// import ManageCallDisplayNames from '../views/user/caller/ManageCallDisplayNames.vue';
// import AddOrEditEncounterNote from '../views/user/encounter_note/AddOrEditEncounterNote.vue';
// import Notes from '../views/user/encounter_note/Notes.vue';
// import EulaPage from '../views/public/EulaPage.vue';

const routes = [
  //Routes users can visit without logging in
  {
    name: 'Landing',
    path: '/',
    component: Login,
    meta: { requiresVisitor: true },
  },
  {
    name: 'Login',
    path: '/login',
    component: Login,
    meta: { requiresVisitor: true },
  },
  {
    name: 'Registration',
    path: '/registration',
    component: Registration,
    meta: { requiresVisitor: true, title: 'Registration' },
  },
  {
    name: 'Lectures',
    path: '/lectures',
    component: Lectures,
    meta: { title: 'Lectures' },
  },
  {
    name: 'SingleLecture',
    path: '/lecture/:lectureId',
    component: SingleLecture,
  },
  {
    name: 'VerifyEmail',
    path: '/verifyemail/:userId/:code',
    component: VerifyEmail,
    meta: { requiresVisitor: true },
  },
  {
    name: 'CorticoConsultRequest',
    path: '/cortico/consult-request/:requestCode',
    component: CorticoConsultRequest,
    meta: { requiresAuth: false },
  },
  // The following link will no longer be useful for future invites, but any invites already sent before 31st July 2024 needs this handler
  {
    name: 'UserInviteAcceptLinkHandlerOld',
    path: '/join-hallway/:inviteCode',
    component: UserInviteAcceptLinkHandler,
    meta: { requiresAuth: false },
  },
  {
    name: 'UserInviteAcceptLinkHandler',
    path: '/accept-invite/:inviteCode',
    component: UserInviteAcceptLinkHandler,
    meta: { requiresAuth: false },
  },
  // Everything else is lazyloaded/prefetched after initial chunk load
  {
    name: 'DemoLogin',
    path: '/cep',
    component: () => import('../views/public/DemoLogin.vue'),
    meta: { requiresVisitor: true },
  },
  {
    name: 'DemoLoginNovaScotia',
    path: '/cep-ns',
    component: () => import('../views/public/DemoLogin.vue'),
    meta: { requiresVisitor: true },
  },
  {
    name: 'AccountSetupPage',
    path: '/account-setup',
    component: () => import('../views/user/onboarding/AccountSetupPage.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'Logout',
    path: '/logout',
    component: () => import('../views/user/Logout.vue'),
  },
  {
    name: 'RegistrationThankYou',
    path: '/thank-you',
    component: () => import('../views/public/registration/RegistrationThankYou.vue'),
    meta: { requiresVisitor: true },
  },
  {
    name: 'GeneralPrivacyPolicy',
    path: '/privacy-policy',
    component: () => import('../views/public/privacy/GeneralPrivacyPolicy.vue'),
    meta: { requiresVisitor: true },
  },
  {
    // may need updating
    name: 'PlatformPrivacyPolicy',
    path: '/platform-privacy-policy',
    component: () => import('../views/public/privacy/PlatformPrivacyPolicy.vue'),
    meta: { requiresVisitor: true },
  },
  {
    // may need updating
    name: 'TermsOfService',
    path: '/terms-of-service',
    component: () => import('../views/public/privacy/TermsOfService.vue'),
    meta: { requiresVisitor: true },
  },
  {
    name: 'Home',
    path: '/home',
    component: () => import('../views/user/Home.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'ReferralHistory',
    path: '/referralhistory',
    component: () => import('../views/user/referral/History.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'Billing',
    path: '/billing',
    component: () => import('../views/user/billing/Billing.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'ReferralTriage',
    path: '/referraltriage',
    component: () => import('../views/user/referral/ReferralTriage.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'AllNotifications',
    path: '/allnotifications',
    component: () => import('../views/user/my_account/AllNotifications.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'Referral',
    path: '/referral',
    component: () => import('../views/user/referral/Show.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'ReferralDetail',
    path: '/referral/:id',
    component: () => import('../views/user/referral/Show.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'MakeReferral',
    path: '/makereferral',
    component: () => import('../views/user/gp/MakeReferral.vue'),
    meta: { requiresAuth: true },
    props: true,
  },
  {
    name: 'Schedules',
    path: '/schedules',
    component: () => import('../views/user/specialist/schedule/All.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'Preferences',
    path: '/preferences',
    component: () => import('../views/user/specialist/Preferences.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'ExampleConsultForm',
    path: '/exampleconsultform',
    component: () => import('../views/user/specialist/ExampleConsultForm.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'DemoReferral',
    path: '/demoreferral',
    component: () => import('../views/user/specialist/ExampleConsultForm.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'Verification',
    path: '/verification',
    component: () => import('../views/user/onboarding/Onboarding.vue'),
    meta: { requiresAuth: true },
  },
  {
    // maybe remove
    name: 'LegacyReRegister',
    path: '/legacyreregister',
    component: () => import('../views/user/legacy_reregister/LegacyReRegister.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'AddSchedule',
    path: '/addschedule',
    component: () => import('../views/user/specialist/schedule/AddSchedule/AddSchedule.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'ShowMyAccount',
    path: '/myaccount',
    component: () => import('../views/user/my_account/ShowMyAccount.vue'),
    meta: { requiresAuth: true },
  },
  {
    // maybe remove
    name: 'InviteColleague',
    path: '/inviteuser',
    component: () => import('../views/user/InviteColleague.vue'),
  },
  {
    name: 'LectureCredits',
    path: '/lecturecredits',
    component: () => import('../views/user/lectures/LectureCredits.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'PasswordResetRequest',
    path: '/passwordreset',
    component: () => import('../views/public/PasswordResetRequest.vue'),
  },
  {
    name: 'PasswordReset',
    path: '/passwordreset/:userId/:code',
    component: () => import('../views/public/PasswordReset.vue'),
  },
  {
    name: 'EmailNotVerified',
    path: '/emailnotverified',
    component: () => import('../views/public/EmailNotVerified.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'CloseAccount',
    path: '/closeaccount',
    component: () => import('../views/user/my_account/CloseAccount.vue'),
  },
  {
    name: 'ManageUsers',
    path: '/manageusers',
    component: () => import('../views/user/org_unit/ManageUsers.vue'),
  },
  {
    name: 'AddUser',
    path: '/manageusers/adduser',
    component: () => import('../views/user/org_unit/AddUser.vue'),
  },
  {
    name: 'EditUser',
    path: '/editaccount/:userId',
    component: () => import('../views/user/org_unit/EditUser.vue'),
  },
  {
    name: 'InviteUser',
    path: '/manageusers/inviteuser',
    component: () => import('../views/user/org_unit/InviteUser.vue'),
  },
  {
    name: 'AddExistingUserToOrgUnit',
    path: '/permission/allowtobeadded/:userId/:orgUnitId/:code',
    component: () => import('../views/user/org_unit/AddExistingUser.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'ShareConsults',
    path: '/shareconsults',
    component: () => import('../views/user/referral/ShareReferralsWithOrgUnit.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'SmartPaths',
    path: '/smartpaths',
    component: () => import('../views/user/specialist/smartpath/SmartPaths.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'SmartPathBuilder',
    path: '/builder/:smartPathId',
    component: () => import('../views/user/specialist/smartpath/Builder.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'SmartPathSurvey',
    path: '/pathway/:smartPathId/:contributorId?/:transactionId?',
    component: () => import('../views/user/gp/make_smartpath/SmartPath.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'SmartPathTransactions',
    path: '/results/:smartPathId',
    component: () => import('../views/user/specialist/smartpath/SmartPathTransactions.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'SmartPathTransactionHistory',
    path: '/pathwayhistory',
    component: () => import('../views/user/gp/smartpath/SmartPathTransactionHistory.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'ReviewSmartPathTransaction',
    path: '/reviewresult/:transactionId',
    component: () => import('../views/user/specialist/smartpath/ReviewTransaction.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'Resources',
    path: '/resources',
    component: () => import('../views/user/AllResponseFormRelatedFiles.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'NotifyUserFromTextMessage',
    path: '/nUfT/:code',
    component: () => import('../views/user/NotifyUserFromTextMessage.vue'),
    meta: { requiresAuth: false },
  },
  {
    name: 'VerifyEmailUpdate',
    path: '/verify-email-update/:code',
    component: () => import('../views/public/VerifyEmailUpdate.vue'),
    meta: { requiresAuth: false },
  },
  {
    name: 'RecentCallsHistory',
    path: '/recent-calls-history',
    component: () => import('../views/user/caller/RecentCallsHistory.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'ManageCallDisplayNames',
    path: '/call-display-names',
    component: () => import('../views/user/caller/ManageCallDisplayNames.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'AddEncounterNote',
    path: '/add-note',
    component: () => import('../views/user/encounter_note/AddOrEditEncounterNote.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'Notes',
    path: '/notes',
    component: () => import('../views/user/encounter_note/Notes.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'EditEncounterNote',
    path: '/note/:id',
    component: () => import('../views/user/encounter_note/AddOrEditEncounterNote.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'EULA',
    path: '/eula',
    component: () => import('../views/public/EulaPage.vue'),
    meta: { requiresAuth: false },
  },
  { path: '/:pathMatch(.*)*', component: PageNotFound },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 }; // always scroll to top on route change
  },
});

//
async function preloadAsyncRoutes() {
  for (const route of router.getRoutes()) {
    if (!route.components) continue;
    // console.log(route.name);
    for (const componentOrImporter of Object.values(route.components)) {
      if (typeof componentOrImporter === 'function') {
        try {
          // prefetch the component and wait until it finishes before moving to the next one
          await componentOrImporter();
        } catch (err) {}
      }
    }
  }
}

window.addEventListener('load', preloadAsyncRoutes);

router.beforeEach(async (to, from) => {
  const isAuthenticated = await store.getters.isLoggedIn;
  const isPreboarding = await store.getters.isPreboarding;
  const isOnboarding = await store.getters.isOnboarding;
  const isLegacyNeedReRegister = await store.getters.isLegacyNeedReRegister;
  const loggedInUser = await store.getters.loggedInUser;
  const onboardingForm = await store.getters.onboardingForm;
  const isEmailActivated = await store.getters.isEmailActivated;
  const isAllowedMakeReferral = await store.getters.isAllowedMakeReferral;
  const isOrgUnitHead = await store.getters.isOrgUnitHead;
  const isBookingHallwayAdmin = globalMixin.methods.isBookingHallwayAdmin(loggedInUser);
  const actingUser = await store.getters.actingUser;
  const isCme = await store.getters.isCme;
  const isActive = await store.getters.isActive;

  let smartPathRoutes = ['SmartPaths', 'ShowSmartPathTransactions', 'SmartPathTransactionHistory', 'SmartPathTransactions', 'SmartPathSurvey', 'SmartPathBuilder'];
  if (to.meta.requiresAuth && !isAuthenticated) {
    return {
      path: '/login',
      // save the location we were at to come back later
      query: { redirect: to.fullPath },
    };
  } else if (to.name != 'EmailNotVerified' && isAuthenticated && !isEmailActivated) {
    return {
      path: '/emailnotverified',
    };
  } else if (isAuthenticated && !isOrgUnitHead(loggedInUser) && actingUser === null) {
    await store
      .dispatch('logout')
      .then(() => {
        router.go();
      })
      .catch(() => {});
  } else if (isAuthenticated && isPreboarding(loggedInUser) && to.name !== 'AccountSetupPage' && to.name !== 'Lectures' && to.name !== 'SingleLecture' && to.name !== 'LectureCredits' && to.name !== 'Logout' && to.name !== 'ShowMyAccount' && to.name !== 'CloseAccount') {
    return {
      path: '/account-setup',
    };
  } else if (isAuthenticated && to.name == 'AccountSetupPage' && !isPreboarding(loggedInUser)) {
    return {
      path: '/home',
    };
  } else if (isAuthenticated && smartPathRoutes.includes(to.name) && (loggedInUser.practice_province.name !== 'Nova Scotia' || loggedInUser.provider_type === 'Pharmacist' || isBookingHallwayAdmin)) {
    return {
      path: '/home',
    };
  } else if (isAuthenticated && to.name == 'Billing' && (isPreboarding(loggedInUser) || isBookingHallwayAdmin || loggedInUser.provider_type == 'Pharmacist' || (isOrgUnitHead(loggedInUser) && !isActive(loggedInUser)))) {
    return {
      path: '/home',
    };
  } else if (onboardingForm) {
    if (isAuthenticated && !onboardingForm.is_eula_agreed && to.name != 'EULA') {
      return {
        path: '/eula',
      };
    } else if (isAuthenticated && isLegacyNeedReRegister(loggedInUser) && to.name != 'LegacyReRegister' && onboardingForm.is_eula_agreed && to.name != 'Logout') {
      return {
        path: '/legacyreregister',
      };
    } else if (isAuthenticated && isOnboarding(loggedInUser) && !isAllowedMakeReferral && to.name == 'MakeReferral' && to.name != 'LegacyReRegister') {
      return {
        path: '/verification',
      };
    } else if (isAuthenticated && !isLegacyNeedReRegister(loggedInUser) && to.name == 'LegacyReRegister') {
      return {
        path: '/home',
      };
    } else if (isAuthenticated && onboardingForm.is_eula_agreed && to.name == 'EULA') {
      return {
        path: '/home',
      };
    } else if ((to.name == 'EditUser' || to.name == 'ManageUsers' || to.name == 'AddUser') && !isOrgUnitHead(loggedInUser) && !isBookingHallwayAdmin) {
      return {
        path: '/home',
      };
    } else if (to.meta.requiresVisitor && isAuthenticated && !isPreboarding(loggedInUser)) {
      return {
        path: '/home',
      };
    } else if (globalMixin.methods.isNativePlatform() && ['AddEncounterNote', 'Notes', 'EditEncounterNote'].includes(from.name) && !['AddEncounterNote', 'Notes', 'EditEncounterNote'].includes(to.name)) {
      return {
        path: from.path,
      };
    }
    // else if (globalMixin.methods.isNativePlatform() && !isCme(loggedInUser) && ['Lectures', 'SingleLecture', 'LectureCredits'].includes(from.name) && !['Lectures', 'SingleLecture', 'LectureCredits'].includes(to.name)) {
    //   return {
    //     path: from.path,
    //   };
    // }
    else if (globalMixin.methods.isNativePlatform() && ['RecentCallsHistory'].includes(from.name) && !['RecentCallsHistory'].includes(to.name)) {
      return {
        path: from.path,
      };
    }
    // else if (globalMixin.methods.isNativePlatform() && isCme(loggedInUser) && !['Lectures', 'SingleLecture', 'LectureCredits', 'AccountSetupPage', 'ShowMyAccount', 'Logout'].includes(to.name)) {
    //   return {
    //     path: from.path,
    //   };
    // }
  }
});

router.afterEach((to) => {
  // We set the title and meta description with the <Head> component in SingleLecture.vue
  if (to.name != 'SingleLecture') {
    if (to.meta.title) {
      document.title = `${to.meta.title} - Virtual Hallway`;
    } else {
      document.title = `Virtual Hallway`;
    }
  }
});

export default router;
